<template>
  <div class="p-6 loading-table" v-if="showPndingAjaxLoad || loading">
    <spinner-color />
  </div>
  <div v-else>
    <data-table-order
      :data="this.$store.state.dataList.orders"
      :header="header"
      :body="body"
      :titlePage="titlePage"
    />
  </div>
</template>

<script>
import DataTableOrder from '../components/table-components/DataTableOrder.vue'
import { mapActions } from 'vuex'
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue'
import globalMixin from '@/mixins/mixins.js'

export default {
  mixins: [globalMixin],
  components: {
    DataTableOrder,
    SpinnerColor
  },
  data () {
    return {
      header: [
        { header: 'sentDate', key: 'sent_at' },
        { header: 'theDateOfDelivery', key: 'due_date' },
        { header: 'dueDate', key: 'execution_date' },
        { header: 'code', key: 'code' },
        { header: 'sender', key: 'second_client' },
        { header: 'receiver', key: 'receiver' },
        { header: 'government', key: 'gov_name' },
        { header: 'area', key: 'location' },
        // {header: 'address', key: 'address'},
        { header: 'notes', key: 'notes' },
        { header: 'collectedAmount', key: 'amount_to_be_collected' },
        { header: 'branch', key: 'branch_name' }
      ],
      body: [
        'sent_at',
        'due_date',
        'execution_date',
        'code',
        'second_client',
        'receiver',
        'gov_name',
        'location',
        'notes',
        'amount_to_be_collected',
        'branch_name'
      ],
      titlePage: 'searchResults',
      loading: false
    }
  },
  watch: {
    '$route.query.obj': {
      async handler () {
        this.loading = true
        await this.advancedSearch(this.$route.query.obj)
        this.loading = false
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('dataList', ['advancedSearch'])
  },
  async created () {
    await this.advancedSearch(this.$route.query.obj)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_loadingTable.scss";
</style>
